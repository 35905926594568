import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import Menu from "../components/v2023/Blog/Menu/menu"
import CardPost from "../components/v2023/UI/Card/card-post"
import Identity from "../components/v2023/Identity/identity"
import Certifications from "../components/v2023/Certifications/certifications"
import { StaticImage } from "gatsby-plugin-image"


const NewBlogSearch = ({ data, pageContext, location }) => {
    const lang = pageContext.langKey
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const params = new URLSearchParams(location?.search)
    const text = params.get("text")
    const page = data.page.edges[0].node
    const categories = data.categories.edges
    const recommended = data.recommended.edges
    const blog = data.blog.frontmatter
    const posts = data.posts.edges.filter(post => post.node.name.toLowerCase().includes(text))

    const title = {
        "es": `${posts.length} resultados para ${text}`,
        "en": `${posts.length} results for ${text}`
    }

    const empty = {
        "es": `No hay resultados para ${text}`,
        "en": `No results for ${text}`
    }

    pageContext.translates = {
        en: "/blog",
        es: "/es/blog"
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
            />

            <main className="main">
                <Menu categories={categories} blog={blog} lang={lang} />
                <div className="main__section main__section--100 white-section brown">
                    <div className="container container--slim" data-aos="fade-up">
                        {posts.length <= 0 &&
                            <div className="container__empty">
                                <StaticImage
                                    src={"../new/resources/blog/empty.svg"}
                                    alt="No results"
                                    placeholder="blurred"
                                />
                                <h3>{empty[pageContext.langKey]}</h3>
                            </div>
                        }
                        {posts.length > 0 &&
                            <h1>{title[pageContext.langKey]}</h1>
                        }

                        {posts.map((post, index) => {
                            return <CardPost
                                lang={lang}
                                name={post.node.name}
                                image={post.node.photo_2}
                                url={`${baseUrl}/blog/${post.node.url}`}
                                tag={post.node.blog_categories[0].name}
                                tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                            />
                        })}
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{blog.recommended}</h2>
                        <div className="grid-lg-3-h">
                            {recommended.map((post, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <CardPost key={index}
                                                 lang={lang}
                                                 name={post.node.name}
                                                 image={post.node.photo_2}
                                                 url={`${baseUrl}/blog/${post.node.url}`}
                                                 date={post.node.creation_datetime}
                                                 tag={post.node.blog_categories[0].name}
                                                 tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                                 showMore={true}
                                                 animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <Certifications />
                <Identity lang={lang} />

            </main>
        </NewLayout>
    )
}

export default NewBlogSearch

export const blogSearchQuery = graphql`
    query ($langKey: String!) {
        page: allStrapiBlog (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    search_title
                    search_placeholder
                    search_button
                    search_show_all
                }
            }
        }
        categories: allStrapiBlogCategories (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    id
                    name
                    url
                }
            }
        }
        blog: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "blog"
                }
            }) {
            frontmatter {
                url
                subscribe
                see_more
                see_less
                view_more
                recommended
                explore
                newsletter {
                    title
                    description
                }
            }
        }
        recommended: allStrapiBlogPosts (
            filter: { recommended: {eq: true}, locale: {eq: $langKey} }
            sort: { fields: [creation_datetime], order: [DESC] }
            limit: 3
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 912
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    creation_datetime(formatString: "DD/MM/YYYY", locale: $langKey)
                }
            }
        }
        posts: allStrapiBlogPosts (
            sort: { fields: [creation_datetime], order: [DESC] }
            filter: { locale: {eq: $langKey} }
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 700
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    content
                }
            }
        }
    }
`
